$(function () {
    var player = new Plyr.setup('.video-player', {
        controls: ['play-large', 'airplay', 'fullscreen'],
    });

    $.each(player, function () {
        var pl = $(this)[0];
        $(pl.elements.container).click(function () {
            if ($(this).is('.plyr--playing')) {
                // var element = $(this).siblings('.overlayVideo');
                // element[0].classList.remove('hide');
                pl.pause();
            } else {
                var element = $(this).siblings('.overlayVideo');
                element[0].classList.add('hide');
                var element2 = $(this).siblings('.uhrenbezeichnung');
                element2[0].classList.add('hide');
            }
        })
    })
});

$(function() {

});